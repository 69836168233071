import React, {useEffect} from 'react';
import axios from "../../lib/axios";
import {useSearchParams} from 'react-router-dom'
import Paginator from "../Paginator/Paginator";
import Accordion, {AccordionItem} from "../Accordion/Accordion";
import Checkbox from "../Checkbox/Checkbox";
import LocalAuthorities from "../../types/LocalAuthorities";
import ProductTypes from "../../types/ProductTypes";
import BusinessTypes from "../../types/BusinessTypes";
import PremisesTypes from "../../types/PremisesTypes";
import FloorAreas from "../../types/FloorAreas";

const PublicRegister = function ({props}) {

    let [searchParams, setSearchParams] = useSearchParams();

    const [searchResults, setSearchResults] = React.useState([])
    const [searchTerm, setSearchTerm] = React.useState(searchParams.get('query') ?? "")
    const [totalRecords, setTotalRecords] = React.useState(0)
    const [totalPages, setTotalPages] = React.useState("1")

    const [pageRangeFrom, setPageRangeFrom] = React.useState(0)
    const [pageRangeTo, setPageRangeTo] = React.useState(0)

    const [currentPage, setCurrentPage] = React.useState("1")
    const [filteredSearchPerformed, setFilteredSearchPerformed] = React.useState(!!searchParams.get('query'))
    const [currentSearchTerm, setCurrentSearchTerm] = React.useState("")

    const [filtersApplied, setFiltersApplied] = React.useState(false)

    const [selectedPremises, setSelectedPremises] = React.useState(null)

    const validateParams = (paramName, valueObject) => {
        const paramsArray  = searchParams.has(paramName) ? decodeURIComponent(searchParams.get(paramName)).split(',') : []

        const permittedValues = valueObject.map(function(value) {
            return value.value;
        });

        return permittedValues.filter(x => paramsArray.includes(x))
    }

    const localAuthorities = validateParams('LocalAuthorities', LocalAuthorities)
    const productsSold = validateParams('ProductsSold', ProductTypes)
    const businessType = validateParams('BusinessType', BusinessTypes)

    const [searchFilters, setSearchFilters] = React.useState({
        LocalAuthorities: localAuthorities,
        ProductsSold: productsSold,
        BusinessType: businessType,
    })
    const [appliedFilters, setAppliedFilters] = React.useState({
        LocalAuthorities: localAuthorities,
        ProductsSold: productsSold,
        BusinessType: businessType,
    })

    const getResults = async (page = 1) => {
        const requestSearchParams = new URLSearchParams();

        if (searchTerm) {
            requestSearchParams.append("query", searchTerm);
        }

        for (const [key, value] of Object.entries(searchFilters)) {
            if (value.length > 0) {
                requestSearchParams.append(key, value.toString());
                setFiltersApplied(true)
            }
        }

        const response = await axios.get(`/public-register/search?page=${page}&${requestSearchParams.toString()}`)

        if(response.status === 422) {
            getResults(1)
        }

        setTotalRecords(response.data.meta.total)
        setSearchResults(response.data.data)
        setCurrentPage(response.data.meta.current_page)
        setTotalPages(response.data.meta.last_page)

        setPageRangeFrom(response.data.meta.from)
        setPageRangeTo(response.data.meta.to)

        if (parseInt(page) > 1) {
            requestSearchParams.append('page', page)
        }

        setFilteredSearchPerformed(requestSearchParams.size > 0)
        setAppliedFilters({...structuredClone(searchFilters)})

        setCurrentSearchTerm(searchTerm)
        setSearchParams(requestSearchParams)
    };

    const getAddressString = (premises) => {
        if(premises.type === "online") {
            return premises.website_url
        }
        return premises.address
    }

    const clearFiltersHandler = () => {
        setSearchTerm("")
        setCurrentSearchTerm("")
        removeCheckboxFilters()
        setFiltersApplied(false)
        setFilteredSearchPerformed(false)
    }

    const removeCheckboxFilters = () => {
        setSearchFilters(
            {
                ...searchFilters,
                LocalAuthorities: [],
                ProductsSold: [],
                BusinessType: [],
            })

    }

    useEffect(() => {
        if (!filteredSearchPerformed)
            getResults(1)
    }, [filteredSearchPerformed])

    const pageClickHandler = (page) => {
        getResults(page)
    }

    const searchHandler = (e) => {
        e.preventDefault()
        setCurrentSearchTerm(searchTerm)
        getResults(1)
    }

    const premisesSelectedHandler = (e, premises) => {
        e.preventDefault()
        searchParams.append('premises', premises.id)
        setSearchParams(searchParams)
        setSelectedPremises(premises)
    }

    const backToSearchHandler = (e) => {
        e.preventDefault()
        searchParams.delete('premises')
        setSearchParams(searchParams)
        setSelectedPremises(null)
    }

    const removeFilterHandler = (event, filterName, value) => {
        event.preventDefault()
        let filterArray = searchFilters[filterName]
        const index = filterArray.indexOf(value);
        if (index > -1) {
            filterArray.splice(index, 1);
        }
        setSearchFilters({...searchFilters, [filterName]: filterArray})
        getResults(1)
    }

    const filterSelectedHandler = (target, filterName) => {
        const {checked, value} = target;

        let filterArray = searchFilters[filterName];

        if (searchFilters[filterName].includes(value) && !checked) {
            const index = filterArray.indexOf(value);
            if (index > -1) {
                filterArray.splice(index, 1);
            }
        } else {
            filterArray.push(value)
        }
        setSearchFilters({...searchFilters, [filterName]: filterArray})
    }

    const applyFilterHandler = (event) => {
        searchHandler(event)
    }

    useEffect(() => {
        setSearchTerm(searchParams.get('query') ?? "")
        setFilteredSearchPerformed(!!searchParams.get('query'))
        setCurrentSearchTerm(searchTerm)
        getResults(searchParams.get('page') ?? 1)
    }, [])

    return (
        <>
            <main className="ds_layout  ds_layout--search-results--filters">
                {selectedPremises !== null && (
                    <>
                        <div className="ds_layout__header">
                            <nav aria-label="Breadcrumb">
                                <ol className="ds_breadcrumbs">
                                    <li className="ds_breadcrumbs__item">
                                        <a className="ds_breadcrumbs__link" href="#" onClick={event => backToSearchHandler(event)}>
                                            Back to search
                                        </a>
                                    </li>
                                </ol>
                            </nav>
                            <header className="ds_page-header">
                                <h1 className="ds_page-header__title">{selectedPremises.premisesName}</h1>
                            </header>
                        </div>
                        <div className="ds_layout__content">
                            <ol class="ds_summary-list">
                            <li class="ds_summary-list__item">
                                <span className="ds_summary-list__key" id="q1">Status</span>
                                <span className="ds_summary-list__value"><q className="ds_summary-list__answer">Active</q></span>
                            </li>
                            <li class="ds_summary-list__item">
                                <span className="ds_summary-list__key" id="q2">Type of Premises</span>
                                <span className="ds_summary-list__value"><q className="ds_summary-list__answer">{PremisesTypes.find(({value}) => value === selectedPremises.type).name}</q></span>
                            </li>
                            <li class="ds_summary-list__item">
                                <span className="ds_summary-list__key" id="q3">Operating Local Authority</span>
                                <span className="ds_summary-list__value"><q className="ds_summary-list__answer">{LocalAuthorities.find(({value}) => value === selectedPremises.localAuthority).name}</q></span>
                            </li>
                            <li class="ds_summary-list__item">
                                <span className="ds_summary-list__key" id="q4">Premises Address</span>
                                <span className="ds_summary-list__value"><q className="ds_summary-list__answer">{getAddressString(selectedPremises)}</q></span>
                            </li>
                            <li class="ds_summary-list__item">
                                <span className="ds_summary-list__key" id="q5">Products Sold</span>
                                <span className="ds_summary-list__value"><q className="ds_summary-list__answer">{ProductTypes.find(({value}) => value === selectedPremises.productsSold).name}</q></span>
                            </li>
                            <li class="ds_summary-list__item">
                                <span className="ds_summary-list__key" id="q5">Type of Business at Premises</span>
                                <span className="ds_summary-list__value"><q className="ds_summary-list__answer">{selectedPremises.businessAtPremises}</q></span>
                            </li>
                            <li class="ds_summary-list__item">
                                <span className="ds_summary-list__key" id="q5">Floor Area</span>
                                <span className="ds_summary-list__value"><q className="ds_summary-list__answer">{FloorAreas.find(({value}) => value === selectedPremises.floorArea).name}</q></span>
                            </li>
                        </ol>
                        </div>
                    </>
                )}

                {selectedPremises === null && (
                    <>
                        <div className="ds_layout__header">
                            <header className="ds_page-header">
                                <h1 className="ds_page-header__title">Search</h1>
                            </header>
                        </div>
                        <div className="ds_layout__content">
                            <div className="ds_site-search">
                                <form role="search" className="ds_site-search__form">
                                    <label className="ds_label  visually-hidden" for="site-search">Search</label>
                                    <div className="ds_input__wrapper  ds_input__wrapper--has-icon">
                                        <input name="q" required="" id="site-search"
                                               className="ds_input  ds_site-search__input"
                                               type="search" placeholder="Search" autocomplete="off" value={searchTerm}
                                               onChange={event => setSearchTerm(event.target.value)}/>
                                        <button type="submit" className="ds_button  js-site-search-button"
                                                onClick={event => searchHandler(event)}>
                                            <span className="visually-hidden">Search</span>
                                            <svg className="ds_icon" aria-hidden="true" role="img">
                                                <svg viewBox="0 0 24 24" id="search" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 0h24v24H0z" fill="none"/>
                                                    <path
                                                        d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                                                </svg>
                                            </svg>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="ds_layout__sidebar">
                            <div className="ds_search-filters">
                                <div className="ds_details  ds_no-margin" data-module="ds-details">
                                    <input id="filters-toggle" type="checkbox"
                                           className="ds_details__toggle  visually-hidden"/>
                                    <label for="filters-toggle" className="ds_details__summary">
                                        Search filters
                                    </label>
                                    <div className="ds_skip-links  ds_skip-links--static">
                                        <ul className="ds_skip-links__list">
                                            <li className="ds_skip-links__item"><a className="ds_skip-links__link"
                                                                                   href="#search-results">Skip to
                                                results</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="ds_details__text">
                                        <form id="filters">
                                            <h3 className="ds_search-filters__title  ds_h4">Filter by</h3>
                                            <Accordion small="true">
                                                <AccordionItem id="local-authority" title="Local Authority">
                                                    <fieldset>
                                                        <legend className="visually-hidden">Select which local
                                                            authorities
                                                            you
                                                            would like to see
                                                        </legend>
                                                        <div className="ds_search-filters__scrollable">
                                                            <div className="ds_search-filters__checkboxes">
                                                                {
                                                                    LocalAuthorities.map(authority =>
                                                                        <Checkbox id={authority.value}
                                                                                  checked={searchFilters.LocalAuthorities.includes(authority.value)}
                                                                                  label={authority.name}
                                                                                  value={authority.value}
                                                                                  onChange={event => filterSelectedHandler(event.currentTarget, "LocalAuthorities")}
                                                                                  small/>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </AccordionItem>
                                                <AccordionItem id="business-type" title="Business Type">
                                                    <fieldset>
                                                        <legend className="visually-hidden">Select which business types
                                                            you
                                                            would like to see
                                                        </legend>
                                                        <div className="ds_search-filters__scrollable">
                                                            <div className="ds_search-filters__checkboxes">
                                                                {
                                                                    BusinessTypes.map(type =>
                                                                        <Checkbox id={type.value}
                                                                                  checked={searchFilters.BusinessType.includes(type.value)}
                                                                                  label={type.name} value={type.value}
                                                                                  onChange={event => filterSelectedHandler(event.currentTarget, "BusinessType")}
                                                                                  small/>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </AccordionItem>
                                                <AccordionItem id="products-sold" title="Products Sold">
                                                    <fieldset>
                                                        <legend className="visually-hidden">Select which product types
                                                            you
                                                            would like to see
                                                        </legend>
                                                        <div className="ds_search-filters__scrollable">
                                                            <div className="ds_search-filters__checkboxes">
                                                                {
                                                                    ProductTypes.map(product =>
                                                                        <Checkbox id={product.value}
                                                                                  checked={searchFilters.ProductsSold.includes(product.value)}
                                                                                  label={product.name}
                                                                                  value={product.value}
                                                                                  onChange={event => filterSelectedHandler(event.currentTarget, "ProductsSold")}
                                                                                  small/>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </AccordionItem>
                                            </Accordion>
                                            <button type="submit"
                                                    className="ds_button  ds_button--primary  ds_button--small  ds_button--max  ds_no-margin"
                                                    onClick={event => applyFilterHandler(event)}>
                                                Apply filter
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ds_layout__list">
                            <div className="ds_search-results">
                                <>
                                    <h2 aria-live="polite"
                                        className="ds_search-results__title">
                                        {currentPage > 1 ? (
                                            <span>Showing {pageRangeFrom} to {pageRangeTo} of {totalRecords} results </span>
                                        ) : (
                                            <span>{totalRecords} result{totalRecords > 1 || totalRecords === 0 ? "s " : " "}</span>
                                        )}
                                        {currentSearchTerm && (
                                            <>
                                                for <span
                                                className="ds_search-results__title-query">{currentSearchTerm}</span>
                                            </>
                                        )}
                                    </h2>
                                    <div className="ds_search-controls">
                                        <div className="ds_skip-links  ds_skip-links--static">
                                            <ul className="ds_skip-links__list">
                                                <li className="ds_skip-links__item"><a className="ds_skip-links__link"
                                                                                       href="#search-results">Skip
                                                    to results</a></li>
                                            </ul>
                                        </div>
                                        {filtersApplied && (
                                            <>
                                                <p className="visually-hidden">There are 6 search filters applied</p>
                                                <dl className="ds_facets__list">
                                                    {appliedFilters.LocalAuthorities.length > 0 && (
                                                        <div className="ds_facet-group">
                                                            <dt className="ds_facet__group-title">
                                                                Local authorities:
                                                            </dt>
                                                            {
                                                                appliedFilters.LocalAuthorities.map((filter, index) =>
                                                                    <>
                                                                        <dd className="ds_facet-wrapper">
                                                                            {index > 0 && (
                                                                                <span aria-hidden="true">or</span>
                                                                            )}
                                                                            <span className="ds_facet">
                                                                            {LocalAuthorities.find(({value}) => value === filter).name}
                                                                                <button type="button"
                                                                                        aria-label={`Remove '${LocalAuthorities.find(({value}) => value === filter).name}' filter`}
                                                                                        className="ds_facet__button"
                                                                                        onClick={event => removeFilterHandler(event, 'LocalAuthorities', filter)}>
                                                                                <svg className="ds_facet__button-icon"
                                                                                     aria-hidden="true"
                                                                                     role="img"
                                                                                     focusable="false">
                                                                                        <svg viewBox="0 -960 960 960"
                                                                                             id="cancel"
                                                                                             xmlns="http://www.w3.org/2000/svg">
        <path
            d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/>
    </svg>
                                                                                </svg>
                                                                            </button>
                                                                        </span>
                                                                        </dd>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    )}

                                                    {appliedFilters.ProductsSold.length > 0 && (
                                                        <div className="ds_facet-group">
                                                            <dt className="ds_facet__group-title">
                                                                Products sold:
                                                            </dt>
                                                            {
                                                                appliedFilters.ProductsSold.map((filter, index) =>
                                                                    <>
                                                                        <dd className="ds_facet-wrapper">
                                                                            {index > 0 && (
                                                                                <span aria-hidden="true">or</span>
                                                                            )}
                                                                            <span className="ds_facet">
                                                                            {ProductTypes.find(({value}) => value === filter).name}
                                                                                <button type="button"
                                                                                        aria-label={`Remove '${ProductTypes.find(({value}) => value === filter).name}' filter`}
                                                                                        className="ds_facet__button"
                                                                                        onClick={event => removeFilterHandler(event, 'ProductsSold', filter)}>
                                                                                <svg className="ds_facet__button-icon"
                                                                                     aria-hidden="true"
                                                                                     role="img"
                                                                                     focusable="false">
                                                                                        <svg viewBox="0 -960 960 960"
                                                                                             id="cancel"
                                                                                             xmlns="http://www.w3.org/2000/svg">
        <path
            d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/>
    </svg>
                                                                                </svg>
                                                                            </button>
                                                                        </span>
                                                                        </dd>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    )}
                                                    {appliedFilters.BusinessType.length > 0 && (
                                                        <div className="ds_facet-group">
                                                            <dt className="ds_facet__group-title">
                                                                Products sold:
                                                            </dt>
                                                            {
                                                                appliedFilters.BusinessType.map((filter, index) =>
                                                                    <>
                                                                        <dd className="ds_facet-wrapper">
                                                                            {index > 0 && (
                                                                                <span aria-hidden="true">or</span>
                                                                            )}
                                                                            <span className="ds_facet">
                                                                            {BusinessTypes.find(({value}) => value === filter).name}
                                                                                <button type="button"
                                                                                        aria-label={`Remove '${BusinessTypes.find(({value}) => value === filter).name}' filter`}
                                                                                        className="ds_facet__button"
                                                                                        onClick={event => removeFilterHandler(event, 'BusinessType', filter)}>
                                                                                <svg className="ds_facet__button-icon"
                                                                                     aria-hidden="true"
                                                                                     role="img"
                                                                                     focusable="false">
                                                                                        <svg viewBox="0 -960 960 960"
                                                                                             id="cancel"
                                                                                             xmlns="http://www.w3.org/2000/svg">
        <path
            d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/>
    </svg>
                                                                                </svg>
                                                                            </button>
                                                                        </span>
                                                                        </dd>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    )}
                                                </dl>
                                            </>
                                        )}
                                        {filteredSearchPerformed && (
                                            <div className="ds_facets">
                                                <button
                                                    className="ds_facets__clear-button  ds_button  ds_button--secondary"
                                                    onClick={() => clearFiltersHandler()}>
                                                    Clear all filters
                                                    <svg className="ds_facet__button-icon" aria-hidden="true" role="img"
                                                         focusable="false">
                                                        <svg viewBox="0 -960 960 960" id="cancel"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/>
                                                        </svg>
                                                    </svg>
                                                </button>

                                            </div>
                                        )}
                                        <hr className="ds_search-results__divider"/>
                                    </div>
                                </>

                                {searchResults.length === 0 && (
                                    <div className="ds_no-search-results">
                                        <p><strong>There are no matching results.</strong></p>
                                        <p>Improve your search results by:</p>
                                        <ul>
                                            <li>double-checking your spelling</li>
                                            <li>using fewer keywords</li>
                                            <li>searching for something less specific</li>
                                        </ul>
                                    </div>
                                )}

                                <ol start="1" id="search-results" className="ds_search-results__list"
                                    data-total={totalRecords}>
                                    {searchResults.map(result => (
                                        <li className="ds_search-result">
                                            <h3 className="ds_search-result__title">
                                                <a className="ds_search-result__link" href="#"
                                                   onClick={event => premisesSelectedHandler(event, result)}>{result.premisesName}</a>
                                            </h3>
                                            <p className="ds_search-result__summary">
                                                {result.address}
                                            </p>
                                            <dl className="ds_search-result__metadata  ds_metadata  ds_metadata--inline">
                                                <div className="ds_metadata__item">
                                                    <dt className="ds_metadata__key">Business Name</dt>
                                                    <dd className="ds_metadata__value">
                                                        {result.businessName}
                                                    </dd>
                                                </div>
                                                <div className="ds_metadata__item">
                                                    <dt className="ds_metadata__key">Premises Type</dt>
                                                    <dd className="ds_metadata__value">
                                                        {result.businessAtPremises}
                                                    </dd>
                                                </div>
                                            </dl>
                                        </li>
                                    ))}
                                </ol>
                                {searchResults.length > 0 && (
                                    <>
                                        <Paginator currentPage={currentPage} numberOfPages={totalPages}
                                                   pageClickHandler={pageClickHandler}/>
                                        <hr/>
                                    </>
                                    )}

                            </div>
                        </div>
                    </>
                )}
            </main>
        </>
    )
};

// todo: Unless you need to use lifecycle methods or local state,
// write your component in functional form as above and delete
// this section.
// class PublicRegister extends React.Component {
//   render() {
//     return <div>This is a component called PublicRegister.</div>;
//   }
// }

const PublicRegisterPropTypes = {
    // always use prop types!
};

PublicRegister.propTypes = PublicRegisterPropTypes;

export default PublicRegister;
