import React, {useEffect} from "react";

const Paginator = function ({currentPage, numberOfPages, pageClickHandler, props}) {
    let secondPageNumber = parseInt(currentPage) - 1
    let thirdPageNumber = parseInt(currentPage)
    let fourthPageNumber = parseInt(currentPage) + 1

    if (parseInt(numberOfPages) < 5 || parseInt(currentPage) < 4) {
        secondPageNumber = 2
        thirdPageNumber = 3
        fourthPageNumber = 4
    } else if (parseInt(currentPage) > parseInt(numberOfPages) - 3) {
        secondPageNumber = parseInt(numberOfPages) - 3
        thirdPageNumber = parseInt(numberOfPages) - 2
        fourthPageNumber = parseInt(numberOfPages) - 1
    }

    const nearStart = parseInt(currentPage) < 4
    const nearEnd = parseInt(currentPage) > parseInt(numberOfPages) - 3

    return (
        <>
            <nav className="ds_pagination" aria-label="Search result pages">
                <ul className="ds_pagination__list">
                    {currentPage > 1 && (
                        <li className="ds_pagination__item">
                            <a aria-label="Previous page"
                               className="ds_pagination__link  ds_pagination__link--text  ds_pagination__link--icon"
                               onClick={() => pageClickHandler(parseInt(currentPage) - 1 )} href="#">
                                <svg className="ds_icon" aria-hidden="true" role="img">
                                    <svg viewBox="0 0 24 24" id="chevron_left" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
                                    </svg>
                                </svg>
                                <span className="ds_pagination__link-label">Previous</span>
                            </a>
                        </li>
                    )}
                    <li className="ds_pagination__item">
                        <a aria-label="Page 1"
                           className={`ds_pagination__link ${1 === parseInt(currentPage) ? "ds_current" : ""}`}
                           onClick={() => pageClickHandler(1)} href="#">
                            <span className="ds_pagination__link-label">1</span>
                        </a>
                    </li>
                    {!nearStart && (
                        <li className="ds_pagination__item" aria-hidden="true">
                            <span className="ds_pagination__link  ds_pagination__link--ellipsis">&hellip;</span>
                        </li>
                    )}
                    {parseInt(numberOfPages) > 2 && parseInt(currentPage) && (
                        <li className="ds_pagination__item">
                            <a aria-label={`Page ${secondPageNumber}`}
                               className={`ds_pagination__link ${secondPageNumber === parseInt(currentPage) ? "ds_current" : ""}`}
                               onClick={() => pageClickHandler(secondPageNumber)} href="#">
                                <span className="ds_pagination__link-label">{secondPageNumber}</span>
                            </a>
                        </li>
                    )}
                    {parseInt(numberOfPages) > 3 && (
                        <li className="ds_pagination__item">
                            <a aria-label={`Page ${thirdPageNumber}`}
                               className={`ds_pagination__link ${thirdPageNumber === parseInt(currentPage) ? "ds_current" : ""}`}
                               onClick={() => pageClickHandler(thirdPageNumber)} href="#">
                                <span className="ds_pagination__link-label">{thirdPageNumber}</span>
                            </a>
                        </li>
                    )}
                    {parseInt(numberOfPages) > 4 && (
                        <li className="ds_pagination__item">
                            <a aria-label={`Page ${fourthPageNumber}`}
                               className={`ds_pagination__link ${fourthPageNumber === parseInt(currentPage) ? "ds_current" : ""}`}
                               onClick={() => pageClickHandler(fourthPageNumber)}>
                                <span className="ds_pagination__link-label">{fourthPageNumber}</span>
                            </a>
                        </li>
                    )}
                    {!nearEnd && parseInt(numberOfPages) > 5 && (
                        <li className="ds_pagination__item" aria-hidden="true">
                            <span className="ds_pagination__link  ds_pagination__link--ellipsis">&hellip;</span>
                        </li>
                    )}
                    {parseInt(numberOfPages) > 1 && (
                        <li className="ds_pagination__item">
                            <a aria-label={`Last page, page ${parseInt(numberOfPages)}`}
                               className={`ds_pagination__link ${parseInt(numberOfPages) === parseInt(currentPage) ? "ds_current" : ""}`}
                               onClick={() => pageClickHandler(parseInt(numberOfPages))} href="#">
                                <span className="ds_pagination__link-label">{numberOfPages}</span>
                            </a>
                        </li>
                    )}
                    {parseInt(currentPage) !== parseInt(numberOfPages) && (
                        <li className="ds_pagination__item">
                            <a aria-label="Next page"
                               className="ds_pagination__link  ds_pagination__link--text  ds_pagination__link--icon"
                               onClick={() => pageClickHandler(parseInt(currentPage) + 1 )} href="#">
                                <span className="ds_pagination__link-label">Next</span>
                                <svg className="ds_icon" aria-hidden="true" role="img">
                                    <svg viewBox="0 0 24 24" id="chevron_right" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
                                    </svg>
                                </svg>
                            </a>
                        </li>
                    )}
                </ul>
            </nav>
        </>
    )
}

export default Paginator;
