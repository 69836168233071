const BusinessTypes = [
    {name: "Bulk suppliers (Airport Shops, wholesalers, etc.)", value: "bulk"},
    {name: "Convenience Store (under 280 square metres)", value: "convenience_store"},
    {name: "Forecourt", value: "forecourt"},
    {name: "Hotel", value: "hotel"},
    {name: "Mobile trader", value: "mobile"},
    {name: "Off-licence", value: "off-license"},
    {name: "Other catering", value: "other_catering"},
    {name: "Other retail", value: "other_retail"},
    {name: "Private Club", value: "private_club"},
    {name: "Public House", value: "public_house"},
    {name: "Restaurant", value: "restaurant"},
    {name: "Specialist Tobacconists", value: "tobacconists"},
    {name: "Supermarket and other retail outlets (over 280 square metres)", value: "supermarket"},
]

export default BusinessTypes