const LocalAuthorities = [
    {value: "ABE", name: "Aberdeen City"},
    {value: "ABD", name: "Aberdeenshire"},
    {value: "ANS", name: "Angus"},
    {value: "AGB", name: "Argyll and Bute"},
    {value: "CLK", name: "Clackmannanshire"},
    {value: "DGY", name: "Dumfries and Galloway"},
    {value: "DND", name: "Dundee City"},
    {value: "EAY", name: "East Ayrshire"},
    {value: "EDU", name: "East Dunbartonshire"},
    {value: "ELN", name: "East Lothian"},
    {value: "ERW", name: "East Renfrewshire"},
    {value: "EDH", name: "Edinburgh, City of"},
    {value: "ELS", name: "Comhairle nan Eilean Siar"},
    {value: "FAL", name: "Falkirk"},
    {value: "FIF", name: "Fife"},
    {value: "GLG", name: "Glasgow City"},
    {value: "HLD", name: "Highland"},
    {value: "IVC", name: "Inverclyde"},
    {value: "MLN", name: "Midlothian"},
    {value: "MRY", name: "Moray"},
    {value: "NAY", name: "North Ayrshire"},
    {value: "NLK", name: "North Lanarkshire"},
    {value: "ORK", name: "Orkney Islands"},
    {value: "PKN", name: "Perth and Kinross"},
    {value: "RFW", name: "Renfrewshire"},
    {value: "SCB", name: "Scottish Borders"},
    {value: "ZET", name: "Shetland Islands"},
    {value: "SAY", name: "South Ayrshire"},
    {value: "SLK", name: "South Lanarkshire"},
    {value: "STG", name: "Stirling"},
    {value: "WDU", name: "West Dunbartonshire"},
    {value: "WLN", name: "West Lothian"}
];

export default LocalAuthorities