import './App.css';
import '@scottish-government/design-system/dist/css/design-system.css';
import PublicRegister from "./components/PublicRegister/PublicRegister";

function App() {
  return (
    <div className="App">
        <PublicRegister></PublicRegister>
    </div>
  );
}

export default App;
